import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import * as GatsbyTypes from '../../graphqlTypes';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import PageTitle from '../../components/PageTitle';
import Banner from '../../components/Banner';
import Grid from '../../components/Grid';
import CenteredBox, { CenteredBoxSizes } from '../../components/CenteredBox';
import { scale } from '../../styled';
import ButtonLink from '../../components/ButtonLink';
import ServicesBlock, { ServiceBlockTypes } from '../../components/ServicesBlock';
import RequestQuoteBlock from '../../components/RequestQuoteBlock';
import ReassuranceBlock from '../../components/ReassuranceBlock';

const MailServicePage: React.FC = () => {
  const { file } = useStaticQuery<GatsbyTypes.GetMailServicePageQuery>(
    graphql`
      query GetMailServicePage {
        file(name: { eq: "service-mail-service" }) {
          id
          childMarkdownRemark {
            id
            html
            frontmatter {
              title
              banner {
                childImageSharp {
                  gatsbyImageData(height: 440, layout: FULL_WIDTH)
                }
              }
              image_1 {
                childImageSharp {
                  gatsbyImageData(width: 864, layout: CONSTRAINED)
                }
              }
              image_2 {
                childImageSharp {
                  gatsbyImageData(width: 500, layout: CONSTRAINED)
                }
              }
              seo {
                title
                description
                image {
                  publicURL
                }
              }
            }
          }
        }
      }
    `
  );

  const seoData = file?.childMarkdownRemark?.frontmatter?.seo;
  /* istanbul ignore next */
  if (!seoData?.title || !seoData.description) {
    throw new Error('[Missing data] Page SEO meta');
  }

  const pageData = file?.childMarkdownRemark?.frontmatter;
  /* istanbul ignore next */
  if (!pageData?.title) {
    throw new Error('[Missing data] Page content');
  }

  const bannerImg = pageData.banner?.childImageSharp?.gatsbyImageData;

  return (
    <Layout hasBlocks={false}>
      <SEO
        title={seoData.title}
        description={seoData.description}
        image={seoData.image?.publicURL}
      />
      <PageTitle>{pageData.title}</PageTitle>
      {bannerImg && <Banner img={bannerImg} />}
      <CenteredBox size={CenteredBoxSizes.S}>
        <p style={{ marginBottom: scale(2) }}>
          Vous souhaitez faire enlever votre courrier à destination de la poste à une heure précise
          ? C’est notre métier. Vous faire économiser du temps, c’est notre volonté. Être un
          interlocuteur privilégié et de confiance pour chaque client, c’est notre rôle.
        </p>
        <h2>Collecte de votre courrier en entreprise</h2>
        <p>
          Pour l’acheminement et l’envoi de vos plis, colis et courriers recommandés jusqu’au bureau
          de poste, gagnez du temps dans votre gestion du courrier en faisant appel à nos coursiers
          !
        </p>
        <p style={{ marginBottom: scale(2) }}>
          Notre fonctionnement est simple : u​n coursier Green Course passe dans vos locaux à une
          heure convenue, prend en charge vos envois postaux avec ou sans affranchissement pour les
          déposer au bureau de Poste et les expédier.
        </p>
        <Grid>
          <React.Fragment>
            <GatsbyImage
              image={pageData.image_1?.childImageSharp?.gatsbyImageData}
              alt=""
              style={{ maxWidth: '100%', borderRadius: scale(2) }}
            />
          </React.Fragment>
          <React.Fragment>
            <h2>Remise de votre courrier</h2>
            <p>
              Vous avez également du courrier entrant à gérer ? Avec une procuration,{' '}
              <b>un coursier Green Course assure la distribution du courrier</b> : il se charge de
              récupérer votre courrier dans votre boite postale puis vous le remet dans vos locaux à
              l’heure convenue.
            </p>
          </React.Fragment>
        </Grid>
        <h2>Nos solutions au meilleur prix pour votre courrier entrant et sortant</h2>
        <p>
          Nous passons probablement déjà dans votre quartier, alors profitez-en,{' '}
          <b>nous vous ferons notre meilleure offre.</b>
        </p>
        <p style={{ textAlign: 'center', marginBottom: scale(2) }}>
          <ButtonLink to="/contact">Personnaliser une tournée</ButtonLink>
        </p>
        <GatsbyImage
          image={pageData.image_2?.childImageSharp?.gatsbyImageData}
          alt=""
          style={{ width: '100%', borderRadius: scale(2) }}
        />
      </CenteredBox>
      <ServicesBlock
        title="Nos autres services"
        data-testid="block-services"
        displayedBlockTypes={[ServiceBlockTypes.PackageDelivery, ServiceBlockTypes.UrgentTransport]}
      />
      <RequestQuoteBlock data-testid="block-request-quote" />
      <ReassuranceBlock id="block-reassurance" data-testid="block-reassurance" />
    </Layout>
  );
};

export default MailServicePage;
